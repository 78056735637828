























import Vue from 'vue';
import axios from 'axios';
import { Component } from 'vue-property-decorator';

@Component
export default class RequestAccessForm extends Vue {
    email = '';
    domain = '';
    valid = false;
    sent = false;

    submit(): void {
        if (!this.valid) return;

        axios.post(
            'https://hooks.slack.com/services/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',
            {
                text: `Email: ${this.email}, domain: ${this.domain}`,
            },
            {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                },
            }
        );

        this.sent = true;
    }

    emailRules = [
        (value: string): boolean | string => !!value || 'Required.',
        (value: string): boolean | string =>
            (value && value.length >= 3) || 'Min 3 characters',
    ];
}
