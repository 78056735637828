































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class CookieUsageModal extends Vue {
    dialog = false;
}
