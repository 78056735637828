





























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import RequestAccessForm from './RequestAccessForm.vue';
import PrivacyPolicyModal from './PrivacyPolicyModal.vue';
import CookieUsageModal from './CookieUsageModal.vue';

@Component({
    components: {
        RequestAccessForm,
        PrivacyPolicyModal,
        CookieUsageModal,
    },
})
export default class LandingPage extends Vue {
    @Prop({ required: true })
    loginUrl!: string;

    link(href: string): void {
        window.location.href = href;
    }
}
